import React from "react"
import { Container, Accordion } from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"


// import { graphql } from 'gatsby';


const aboutTheAuthor = props => (
  <Layout>
        <Seo title="Meeting State Codes & LEED Requirements"/>
        <section className="page-section">

        <Container>

        <h1>Meeting State Codes & LEED Requirements</h1>

        </Container>
        <Container className="singleCol">
        <p className="lead">AeroBarrier can help comply with increasingly rigorous state building codes, as well as meet requirements of green building programs such as LEED and Energy Star.</p>
            <p><strong>California Building Code Requirements</strong></p>
            <Accordion defaultActiveKey="0" className="mb-5">
              <Accordion.Item eventKey="0">
                <Accordion.Header>MULTIFAMILY Air Infiltration Requirement: CALIFORNIA BUILDING CODE 2019 (effective Jan 1 2020)</Accordion.Header>
                <Accordion.Body>
                <p>
                  <strong>As of January 1, 2020 Multifamily buildings that do NOT have balanced ventilation are required to pass a blower door test with ≤ 0.3 cfm per square foot at 50 Pascals. This testing needs to be be performed by a HERS Rater and verified by a building inspector before sign off.</strong> The intent of this code requirement is to assure that if a dwelling unit is “leaky” that exhaust-only ventilation is not used, as that would draw air in from the neighboring dwelling units (balanced ventilation prevents this from occurring in leaky dwelling units.)</p>
                  <p>More info:</p>
                  <em>
                    <p>2019 CA Building Code (LINK: Page-132 of TITLE 24, PART 6, AND ASSOCIATED ADMINISTRATIVE REGULATIONS IN PART 1)</p>
                    <p>
                      The mechanical ventilation system shall comply with one of the following subsections 1 or 2 below. When subsection 2 is utilized for compliance, all dwelling units in the multifamily building shall use the same ventilation system type.</p>
                  
                  <p>A balanced mechanical ventilation system shall provide the required dwelling-unit ventilation airflow.</p>
                  <p>Continuously operating supply ventilation systems or continuous operating exhaust ventilation systems shall be allowed to be used to provide the required dwelling unit ventilation airflow if the dwelling-unit envelope leakage is less than or equal to 0.3 cubic feet per minute at 50 Pa (0.2 in. of water) per ft2 of dwelling unit envelope surface area as confirmed by field verification and diagnostic testing in accordance with Reference Nonresidential Appendix NA7.18.2.</p>
                  </em>

                  <p className="text-danger">Don’t fail your blower door test and risk your certificate of occupancy!  Western AeroBarrier will provide you passage with the most cost-effective and guaranteed approach to air sealing your buildings to meet code requirements.</p>
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item eventKey="1">
                <Accordion.Header>SINGLE FAMILY RESIDENTIAL: California Title-24 Modeling Credit for Building Envelope Leakage</Accordion.Header>
                <Accordion.Body>
                <p>
                    On January 1, 2020 the new 2019 California Energy Code went into effect.  Though Single Family Residential does not have an air tightness mandate in this current code cycle, it appears through feedback from Energy Engineers that they are having difficulty getting projects to comply with Title 24 without having to take the Air Infiltration Credit available in the modeling.   If that credit is taken it will show up on Title 24 Reports for projects as a “Required HERS Verification” for a blower door test.</p>
                  <p>
                  California Energy Code will soon eventually change the credit to become a mandate for a minimum level of air leakage.  
                  </p>

                  <p>
                  In fact, California has already adopted the IECC code that requires a target of 3.0 ACH50 (in all Climate Zones other than Zone-2, a small area in the southeast corner of the state, which requires a target of 5.0)  However this requirement of the IECC is not yet being enforced in the California Code. 
                  </p>

                  <p className="text-danger">
                  If your Title-24 lists the BUILDING ENVELOPE AIR LEAKAGE credit as being taken… Don’t fail your blower door test and risk your certificate of occupancy!  Western AeroBarrier will provide you passage with the most cost effective and guaranteed approach to air sealing your buildings to meet code requirements.
                  </p> 
                </Accordion.Body>
              </Accordion.Item>

            </Accordion>
            
            <p><strong>LEED for Homes V4 Requirements</strong></p>

            <Accordion defaultActiveKey="0" className="mb-5">
            <Accordion.Item eventKey="0">
              <Accordion.Header>MULTIFAMILY & ATTACHED HOMES Compartmentalization & Air Infiltration Requirements: LEED for Homes v4</Accordion.Header>
              <Accordion.Body>
              <p>
                    If you are seeking LEED for Homes v4 Certification and are a multifamily or single family attached home, there are prerequisites and credits associated with envelope sealing.
                    </p>
                    <p className="ml-3">
                      <strong>EQ Prerequisite – Compartmentalization</strong><br />
                      Weather-strip all doors in residential units leading to common hallways to minimize air leakage into hallway. Weather strip all exterior doors and operable windows to minimize leakage from outdoors
                    </p>
                    <p className="ml-3">
                    Blower door test – allowable <strong>maximum leakage of 0.23cfm50* per square foot of enclosure</strong> (ie, all surfaces enclosing the apartment, including exterior and party walls, floors, and ceiling.
                    </p>
                    <p className="ml-3">
                    *Projects with small units (defined in EQ c Balancing of Heating and Cooling Systems as multifamily buildings with an average unit size less than 1,200 sq. ft. or single-family homes less than 800 sq. ft.) may meet 0.30 cfm50/sq. ft. enclosure area, rather than 0.23 cfm50
                    </p>
                    <p>
                      <strong>EQ Credit – Enhanced Compartmentalization (Credits are not mandatory for LEED, but earn points)</strong><br />
                      Blower door test has leakage of 0.15cfm50 psf of enclosure (0.15 cubic feet per minute @ 50 Pa)
                    </p>
                    <p>
                      <strong>EA Credit – Air Infiltration</strong><br />
                      Air leakage must be tested and verified by quality energy rater. Meet  &#60;3.5ACH50 or &#60;0.125CFM50
                    </p>
                    <p>
                      <strong>EA Credit – Heating and Cooling Distribution Systems</strong><br />
                      Duct leakage testing for ducts in unconditioned space – points are awarded for systems that test at or below 4cfm/100sf of area served for homes of 1,200sf or less, and 3cfm/100sf for homes larger than 1,200sf
                    </p>
                    <p className="text-danger">Don’t fail your blower door test and risk your LEED certification!  Western AeroBarrier will provide you guaranteed passage with the most cost effective and guaranteed approach to air sealing your buildings to meet LEED prerequisite & credit requirements.</p>
              </Accordion.Body>
            </Accordion.Item>
            </Accordion>

        

</Container>
        </section>
        
      </Layout>
);

export default aboutTheAuthor;

